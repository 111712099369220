import { mdiCheckCircle } from "@mdi/js";
import Icon from "@mdi/react";

interface Props {
  currentIndex: number;
  steps: {
    title?: string;
    subtitle: string;
    minimumIndex: number;
  }[];
}

export default function Stepper({ steps, currentIndex }: Props) {
  return (
    <div className="mt-12">
      <ol className="bg-indigo-900 bg-opacity-80 px-4 rounded-lg w-11/12 sm:w-6/12 mx-auto flex flex-col text-sm font-medium text-gray-500 dark:text-gray-400 sm:text-base justify-between sm:mb-0  sm:py-0">
        {steps.map((s, i) => (
          <li
            className="flex items-center space-x-4 w-full h-16 my-2 sm:my-0" // Ensure consistent height
            key={i}
          >
            {/* Circle/Icon/Step Number */}
            <div className="flex items-center justify-center text-yellow-500 text-xl font-bold bg-blue-600 rounded-full w-12 h-10 sm:w-11 ">
              {currentIndex >= s.minimumIndex ? (
                <Icon path={mdiCheckCircle} className="text-white w-6 h-6" />
              ) : (
                <span className="text-white">{i + 1}</span>
              )}
            </div>

            {/* Content */}
            <div className="flex flex-col w-full h-full text-blue-200 rounded-lg">
              {/* <div className="font-medium">{s.title}</div> */}
              <span className="text-sm text-blue-300 my-auto">{s.subtitle}</span>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
}
