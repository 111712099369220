import { useState } from "react";
import Icon from "@mdi/react";
import {
  mdiMagnify,
  mdiCheck,
  mdiTimerSand,
  mdiMessageQuestion,
  mdiRestart,
  mdiCreditCardOutline,
} from "@mdi/js";
import IntroductionService from "./IntroductionService";
import Stepper from "./Stepper";

/**
 * The Cozy Intro app.
 * @returns CozyIntros App
 */
export default function App() {
  /**
   * Define the Introduction Service that is the gateway to the backend.
   */
  const introductionService = new IntroductionService(
    "https://production-api.cozyintros.com"
  );

  const BILLING_PORTAL_URL =
    "https://billing.stripe.com/p/login/3cs6rd3pUg95bugfYY";

  const DEFAULT_PLACEHOLDER_TEXT = "Insert LinkedIn profile URL here";

  /**
   * These Step are shown at the bottom of the screen
   * Note the minimum index is at what point do we change the number
   * To Check mark. So order matters in this variable, and order matters
   * in {@var ComponentState}
   */
  const steps = [
    {
      subtitle: "Enter the LinkedIn URL of the VIP you’d like to meet.",
      minimumIndex: 4,
    },
    {
      subtitle: "Share why you'd like to meet.",
      minimumIndex: 5,
    },
    {
      subtitle:
        "If we are in touch with the requested VIP, you’ll be prompted to pay $10 in order to send the VIP the request.",
      minimumIndex: 10,
    },
    {
      subtitle: "Close this tab, go about your day and get Cozy!",
      minimumIndex: 10,
    },
    {
      subtitle:
        "If the VIP accepts the request, you’ll receive an email with further instructions.",
      minimumIndex: 10,
    },
    {
      subtitle: "If the connection turns out to be valuable, let us know!",
      minimumIndex: 10,
    },
  ];

  /**
   * Interface defining the structure of our component state
   */
  interface ComponentState {
    inputClassName: string;
    buttonClassName: string;
    searchIconClassName: string;
    timerIconClassName: string;
    checkIconClassName: string;
    arrowIconClassName: string;
    placeHolderText: string;
    weCanConnectYouClassName: string;
    weCannotConnectYouClassName: string;
    restartIconClassName: string;
  }

  /**
   * Component State is how we control the Search Bars State
   * Instead of making everything if statements, we describe how the page
   * looks at different "checkpoints"
   */

  const ComponentStates: { [key: string]: ComponentState } = {
    INITIAL: {
      inputClassName: "w-20 h-20",
      buttonClassName: "opacity-1 bg-yellow-500 hover:bg-yellow-700",
      searchIconClassName: "opacity-1",
      timerIconClassName: "opacity-0",
      checkIconClassName: "opacity-0",
      arrowIconClassName: "opacity-0",
      placeHolderText: DEFAULT_PLACEHOLDER_TEXT,
      weCanConnectYouClassName: "opacity-0",
      weCannotConnectYouClassName: "opacity-0",
      restartIconClassName: "opacity-0",
    },
    FAILURE_LINKEDIN: {
      inputClassName: "w-20 h-20",
      buttonClassName: "bg-red-500 hover:bg-red-600",
      searchIconClassName: "opacity-0",
      timerIconClassName: "opacity-0",
      checkIconClassName: "opacity-0",
      arrowIconClassName: "opacity-0",
      placeHolderText: DEFAULT_PLACEHOLDER_TEXT,
      weCanConnectYouClassName: "opacity-0",
      weCannotConnectYouClassName: "opacity-1",
      restartIconClassName: "opacity-1",
    },
    VALIDATING_LINKEDIN: {
      inputClassName: "h-20 pl-16 w-[400px]",
      buttonClassName:
        "translate-x-[160px] rotate-[360deg] bg-yellow-500 hover:bg-yellow-700",
      searchIconClassName: "opacity-0",
      timerIconClassName: "opacity-1",
      checkIconClassName: "opacity-0",
      arrowIconClassName: "opacity-0",
      placeHolderText: DEFAULT_PLACEHOLDER_TEXT,
      weCanConnectYouClassName: "opacity-0",
      weCannotConnectYouClassName: "opacity-0",
      restartIconClassName: "opacity-0",
    },
    PROMPT_LNKEDIN: {
      inputClassName: "h-20 pl-16 w-[400px]",
      buttonClassName:
        "translate-x-[160px] rotate-[360deg] bg-yellow-500 hover:bg-yellow-700",
      searchIconClassName: "opacity-1",
      timerIconClassName: "opacity-0",
      checkIconClassName: "opacity-0",
      arrowIconClassName: "opacity-0",
      placeHolderText: DEFAULT_PLACEHOLDER_TEXT,
      weCanConnectYouClassName: "opacity-0",
      weCannotConnectYouClassName: "opacity-0",
      restartIconClassName: "opacity-0",
    },
    PROMPT_REASON: {
      inputClassName: "h-20 pl-16 w-[400px]",
      buttonClassName:
        "translate-x-[160px] rotate-[360deg] bg-emerald-500 hover:bg-emerald-700",
      searchIconClassName: "opacity-0",
      timerIconClassName: "opacity-0",
      checkIconClassName: "opacity-0",
      arrowIconClassName: "opacity-1",
      placeHolderText: "Write a quick note of why you want to meet.",
      weCanConnectYouClassName: "opacity-1",
      weCannotConnectYouClassName: "opacity-0",
      restartIconClassName: "opacity-0",
    },
    REDIRECT_TO_STRIPE: {
      inputClassName: "w-20 h-20",
      buttonClassName: "opacity-1 bg-emerald-500 hover:bg-emerald-700",
      searchIconClassName: "opacity-0",
      timerIconClassName: "opacity-0",
      checkIconClassName: "opacity-1",
      arrowIconClassName: "opacity-0",
      placeHolderText: DEFAULT_PLACEHOLDER_TEXT,
      weCanConnectYouClassName: "opacity-0",
      weCannotConnectYouClassName: "opacity-0",
      restartIconClassName: "opacity-0",
    },
  };

  const DEFAULT_CONTACT_NAME = "that contact";
  const [contactName, setContactName] = useState(DEFAULT_CONTACT_NAME);
  const [currentState, setCurrentState] = useState("INITIAL");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [inputTextBoxValue, setInputTextBoxValue] = useState("");
  const {
    inputClassName,
    buttonClassName,
    searchIconClassName,
    timerIconClassName,
    checkIconClassName,
    placeHolderText,
    arrowIconClassName,
    weCannotConnectYouClassName,
    weCanConnectYouClassName,
    restartIconClassName,
  } = ComponentStates[currentState];

  /**
   *
   * @returns
   */
  const handleClickV2 = async () => {
    // Any time we reset, or we are fresh, our next state is prompting the user
    // For linkedin
    if (currentState === "INITIAL" || currentState === "FAILURE_LINKEDIN") {
      setCurrentState("PROMPT_LNKEDIN");
      return;
    }

    // When we are submitting a linkedin profile
    // We want to ensure we don't submit empty stuff
    // If its good, we await a response from the server.
    // *Given the server responds with "yes", we want to allow the user*
    // *Then* clear the input box, so that they can share why they want to meet
    // *Given the server responds with "yes", we want to allow the user*
    // *Then* clear the input box, so that they can share why they want to meet
    if (currentState === "PROMPT_LNKEDIN") {
      if (!inputTextBoxValue || inputTextBoxValue.length === 0) {
        return;
      }
      setCurrentState("VALIDATING_LINKEDIN");

      introductionService
        .isConnectionPossible(inputTextBoxValue)
        .then((response) => {
          if (response.firstName && response.lastName) {
            setContactName(`${response.firstName} ${response.lastName}`);
          } else {
            setContactName(DEFAULT_CONTACT_NAME);
          }

          if (response.result === true) {
            setCurrentState("PROMPT_REASON");
            setLinkedInUrl(inputTextBoxValue);
          } else {
            setCurrentState("FAILURE_LINKEDIN");
          }
        })
        .catch((e) => setCurrentState("FAILURE_LINKEDIN"))
        .finally(() => setInputTextBoxValue(""));
      return;
    }

    if (currentState === "VALIDATING_LINKEDIN") {
      setInputTextBoxValue("");
      setCurrentState("PROMPT_REASON");
      return;
    }

    if (currentState === "PROMPT_REASON") {
      if (!inputTextBoxValue || inputTextBoxValue.length === 0) {
        return;
      }
      setCurrentState("REDIRECT_TO_STRIPE");
      const url = await introductionService.submitPotentialConnection(
        linkedInUrl,
        inputTextBoxValue
      );

      window.location.href = url;
      return;
    }
  };

  return (
    <div className="bg-indigo-950 min-h-screen  flex flex-col items-center justify-center max-h-screen">
      {/* Header */}

      <div className="">
        <img
          src={"./logo.png"}
          alt="Logo"
          className="w-40 h-40 rounded-xl absolute top-4 sm:left-0 sm:p-4 -left-8 hidden sm:block"
        />
      </div>

      <a
        className="sm:w-fit w-16 h-16 rounded-b-xl sm:rounded-t-xl rounded-t-none absolute sm:top-0 sm:right-4 sm:p-4 right-4 top-0 sm:mt-14 text-blue-300 flex items-center justify-center gap-2"
        href={BILLING_PORTAL_URL}
      >
        <Icon path={mdiCreditCardOutline} size={1} />
        <span className="text-center hidden sm:block">Billing</span>
      </a>

      <div className="relative flex items-center justify-center sm:justify-start sm:pl-4">
        <img
          src={"./logo.png"}
          alt="Logo"
          className="w-32 h-32 rounded-xl absolute -left-24 sm:left-0 object-cover object-center sm:hidden"
        />
        <h1 className="text-center font-bold sm:text-5xl text-5xl text-blue-300 sm:text-left">
          Cozy Intros
        </h1>
      </div>

      <div className="relative h-20 sm:w-3/12 w-11/12 mx-auto mt-4">
        <div
          id="WeCanConnectYou"
          className={`${weCanConnectYouClassName} mx-auto w-full transition-opacity duration-1000 delay-100 text-blue-800 bg-emerald-400 text-center py-4 rounded-lg shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 z-10 px-4`}
        >
          {`Great news! ${contactName} is in our network, which means that we can connect you both. Just let us know the reason below.`}
        </div>
        <div
          id="WeCannotConnectYou"
          className={`${weCannotConnectYouClassName} mx-auto w-full transition-opacity duration-1000 delay-100 text-red-800 bg-red-400 text-center p-4 rounded-lg shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 z-0 px-4`}
        >
          {`Unfortunately, we are not connected to ${contactName} and won't be able to facilitate this introduction. Try someone else?`}
        </div>
      </div>

      <div className="relative mt-8 flex justify-center w-full">
        {/* Input Bar */}
        <input
          type="text"
          className={`${inputClassName} sm:mx-auto mx-2 text-sm bg-white border-4 text-blue-500 border-white rounded-full py-3 pl-8 pr-3 placeholder-gray-400 focus:outline-none focus:ring-5 focus:ring-yellow-600 transition-all duration-500`}
          placeholder={placeHolderText}
          value={inputTextBoxValue}
          onChange={(e) => setInputTextBoxValue(e.target.value)}
        />
        <button
          className={`${buttonClassName} w-14 h-14 absolute inset-0 m-auto rounded-full flex items-center justify-center shadow-lg transition-all duration-500 `}
          onClick={handleClickV2}
        >
          <Icon
            path={mdiRestart}
            size={1.5}
            className={`${restartIconClassName} w-20 h-20 text-blue-950 transition-opacity duration-500 absolute rounded-full`}
          />
          <Icon
            path={mdiMagnify}
            size={1.5}
            className={`${searchIconClassName} w-20 h-20 text-blue-950 transition-opacity duration-500 absolute rounded-full`}
          />

          <Icon
            path={mdiTimerSand}
            size={1.5}
            className={`${timerIconClassName} w-20 h-20 text-blue-950 transition-opacity duration-500 absolute rounded-full icon-spin`}
          />

          <Icon
            path={mdiCheck}
            size={1.5}
            className={`${checkIconClassName} w-20 h-20 text-blue-950 transition-opacity duration-500 absolute rounded-full`}
          />

          <Icon
            path={mdiMessageQuestion}
            size={1.5}
            className={`${arrowIconClassName} w-20 h-20 text-blue-950 transition-opacity duration-500 absolute rounded-full icon-pulse-left`}
          />
          <Icon
            path={mdiCheck}
            size={1.5}
            className={`${checkIconClassName} w-20 h-20 text-blue-950 transition-opacity duration-500 absolute rounded-full`}
          />
        </button>
      </div>

      <Stepper
        steps={steps}
        currentIndex={Object.keys(ComponentStates).indexOf(currentState)}
      />
    </div>
  );
}
